.btn {
  border-radius: 0!important;
  box-shadow: none!important;
  font-weight: 300;

  &.btn-default {

    background: linear-gradient(90deg, #da0303 0%, #790909 5% 100%) !important;

    &.pass-vis {
      i {
        color:#fff;
        margin:0.5rem;
      }
    }


  }
}