html,body {
  margin:0!important;
  padding:0!important;
}
#mse-chat {

  min-height: 100%;
  width: 100%;
  height: 100%; /* NEW */

  .chat-wrapper {
    min-height: 100%;
    height:100%;
  }

  .chat-navigation-tab-content-wrapper {
    height:calc(100% - 60px);
    min-height: calc(100% - 60px);
    overflow-y: scroll;
    //border:1px solid #fff;
  }

  .chat-conversation {
    display:none;
    min-height: 100%;
    height:100%;

    .row {
      min-height:100%;
      height:100%;
    }
  }
}

.tab-content {
  display:none;

  &.active {
    display:block;
  }
}


#chat {


  z-index: 500;
  pointer-events: all;
  min-height: 100%;
  height:100%;


  .chat-navigation {
    background-color: rgb(33, 37, 41) !important;
    min-height: 100%;
    height:100%;

    .btn {

      color: #aa0000;

      .network {

      }

    }

  }



  .card {
    border:0px;
    min-height:100%;
    height:100%;

    .conversation-header {
      background-color: #343a40;
      display: flex;
      min-height: 85px;

      i.fas {
        &.fa-arrow-left {
          padding-top:5px;
          margin-top:10px;
          font-size: 1.5rem;
        }
      }

      .conversation-icon {
        margin: 0px 10px 0px 10px;
        border-radius: 50%;
        width: 64px;
        height: 64px;
      }

      .conversation-info {
        flex-grow:8;

        .conversation-info-name {
          font-size: 1.4rem;
          color:#fff;
        }
        .conversation-info-status {

        }
      }

      .conversation-nav {
        flex-grow:1;
      }



    }



    .card-header {
      border:0px;

      i {
        cursor: pointer;
      }
    }

    .card-body {
      //background-color: rgb(45, 49, 54);
      background-color: rgb(33, 37, 41);
      border:0px;

      .chat-message-line {

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .chat-message-wrapper {

          background-color:#343a40;
          border-radius: 0.5rem!important;

          line-height: 17px;
          font-size: 1rem;

          padding: 10px;
          margin: 5px 20px 0px 5px;

          &.me {
            right:0;
            max-width:80%;
            text-align: left;
            align-content: flex-end;
            align-self: flex-end;
            background-color: #3e8f3e;

            .message-username {
              display:none;
            }
          }

          &.other {
            left:0;
            max-width:80%;
            text-align: left;
            align-content: flex-start;
            align-self: flex-start;

            .message-username {
              color: #99aa00;
              font-weight: bold;
              font-size: 1rem;
              padding:5px;
            }
          }

          .chat-message-time {
            color:#fff!important;
            text-align: right;
            float:right;
          }
        }
      }
    }

    .card-footer {

      .cmt-input-wrapper {
        border-radius: 20px 20px 0px 0px;
        padding:20px;
        //background-color: #fff;
        background-color: #343a40;
        position:relative;
        padding-left:80px;
        padding-right:120px;

        .cmt-input-placeholder {
          position:absolute;
          pointer-events:none;
          top:32px;
          left:100px;
          font-size:1rem;
        }

        [contenteditable] {
          outline: 1px solid transparent;
          padding-left:1px;
        }

        .chat-message-text {
          display:block;
          margin: 0px;
          color: #fff;
          font-size: 1.2rem;
          pointer-events: all;
          width: 100%!important;
          outline: 1px solid transparent;
          padding:10px;
          border-radius: 20px;
          background-color: #212529;
          overflow-y:auto;
        }

        .submit-options {
          margin-top: 3px;
          padding: 1px 5px;
          position:absolute;
          right:30px;
          bottom:18px;

          .chat-message-send {
            pointer-events: all;
          }

          .btn-chat {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            margin: 2px 2px 2px 0;
            cursor: pointer;
            display: inline-block;
            padding: 2px;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            min-width: 30px;
            &.submit {
              background: rgb(50, 118, 176);
              border: 1px solid rgb(214, 214, 214);
              border-radius: 50%;
              padding: 10px 20px 10px 16px;
              text-shadow: 0 1px 5px rgba(0,0,0,.25);
              color: #fff;
            }
            .glyphicon-ciggy-o {
              font-size: 3em;
            }
          }
        }
      }


      #chat-message {
        display: inline-block;
        left: 0px;
        position: relative;

        margin-top: 3px;
        margin-bottom: 10px;
        background-image: linear-gradient(to bottom,#f9f9f9,#f2f2f2);


      }

    }
  }



  .usernet {
    z-index: 9999999998;
    //background-image: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#f2f2f2));
    //background-image: linear-gradient(to bottom, #f9f9f9, #f2f2f2);
    //-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    //box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 25px 15px;
    margin-bottom: 5px;
  }

  .toggle-chat-message{
    background: rgb(50, 118, 176);
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5rem;
    padding: 12px 0px;
    margin-left: 0px;
    margin-top: -50px;
    position: sticky;
    color: #dadada;

    i {
      position: relative;
      left: 14px;
    }
  }

  .chat-realtime {
    display: none;
  }


  .chat-monitor {

    display:flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
    overflow-y: auto;
    background: 0 0;
    color: #d3d3d3;
    min-height: calc(100% - 100px);
    height:calc(100% - 100px);

    //border-left: 1px solid #d6d6d6;

    .chat-message-line {

      .chat-message-user {
        color: #454545;
        margin: 8px;
        font-size:1rem;
      }

      .chat-message-id {
        display: none;
      }
      .chat-message-time {
        padding: 1px 2px;
        font-size: 1rem;
        color: #7e7e7e;
        right: 20px;
      }

      .chat-message-content {
        //color: #7a7a7a;
        color: #ffffff;

        //background-image: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
        h3 {
          font-size:1em;
        }

      }
    }
    .mse-overlay {
      text-align: left;
    }
  }








  .chat-message-content {
    font-size: 1.2em;
    line-height: 1.2em;
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif;
    padding-bottom: 10px;
    padding-left: 10px;

  }

}




.usernet .widget-toolbar {
  display:none!important;
}
.usernet .jarviswidget-ctrls {
  display:none!important;
}
.usernet button.refresh-users,
.usernet button.refresh-rooms
{
  position: absolute;
  top:-28px;
  right:5px;
}
.usernet input.room-name {
  width: 85%;
}

.usernet {
  height: 100vh;

}

.btn.network.active {
  color: #3276b0;
}

.send-to-all {
  color: #3276B1;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8em;
}

.chat-video-capture {
  display:none;
}


mse-widget {
  width:100vw;
}

.chat-navigation button i{
  font-size: 1.8rem!important;
}

.chat-rooms {

  .chat-room {

    cursor: pointer;
    display: flex;

    .room-icon-wrapper {

      flex-grow: 1;
      padding-right:20px;


      .room-icon {
        border-radius: 50%;
        width:64px;
        height:64px;
        background-image: url('../../mse/cdn/img/favicon/favicon.png');
      }
    }


    .room-info-wrapper {
      flex-grow: 5;
      width:100%;

      .room-title {
        color: #fff;
        font-weight: bold;
        font-size:1.125rem;
      }

    }

    .room-control-wrapper {
      flex-grow: 1;
      width:10%;
    }

    .room-time-wrapper {
      flex-grow: 1;

      .room-time {
        color:#fff;
        font-size: 0.8rem;
      }

    }
  }

}

.chat-contacts {

  .chat-contact {

    cursor: pointer;
    display: flex;

    .contact-icon-wrapper {

      flex-grow: 1;
      padding-right:20px;


      .contact-icon {
        border-radius: 50%;
        width:64px;
        height:64px;
        background-image: url('../../mse/cdn/img/favicon/favicon.png');
      }
    }


    .contact-info-wrapper {
      flex-grow: 3;
      width:100%;

      .contact-title {
        color: #fff;
        font-weight: bold;
        font-size:1.125rem;
      }

    }

    .contact-time-wrapper {
      flex-grow: 1;

      .contact-time {
        color:#fff;
        font-size: 0.8rem;
      }

    }
  }

}




mse-widget {
  width: 100% !important;
}
.r-line {
  background-image: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
  border-radius: 0px;
  margin: 5px 0 0 0;
  cursor: move;
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  padding: 10px 0px 0px 0px!important;
  opacity: 0.8;

  color: #444;
  text-shadow: 10px 6px 12px #b4b4b4 !important;
}

.r-line .room-name {
  font-size: 1em;
}

input.room-name {
  border-color: #d3d3d3;
  border-radius: 1rem 0rem 0rem 1rem!important;
}