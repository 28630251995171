@import "core/breakpoints";
@import "core/lvp";
@import "core/feedbacks";

@import "core/overlays";
@import "core/userlogin";


.dukih {
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.16);
  border-radius: 8px;
  padding-top: 5px;
  padding-left: 4px;
  width: 648px;
}