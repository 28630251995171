
#overlay-content {
  .audio-player-container {
    .player-container {
      border-radius: unset!important;
      box-shadow: unset!important;
      position: relative;
    }
  }
}

.audio-player-container {

  position: relative;
  display: flex;
  height:100%;

  #player-header {
    z-index:11;
    //background: rgb(218,3,3);
    //background: linear-gradient(90deg, rgba(218,3,3,1) 0%, rgba(121,9,9,1) 5%, rgba(121,9,9,1) 100%)!important;
    padding:1.75rem;
    display:flex;
    justify-content: space-between;

    h3 {
      font-weight: 300!important;
      font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
      font-size: 1.75rem;
      color:#fff;
      display:inline;
    }

    ul.navbar-nav {
      z-index:3000;
      display: flex;
      flex-direction: row;
      align-items: self-end;
      i {
        font-size: 1.5rem;
      }
      a.dropdown-toggle {

        &:after {
          display: none;
        }
      }
      ul.dropdown-menu {
        z-index: 3000;
        li {
          z-index: 3001;
        }
      }
    }


  }

  i {
    color: $lvp-ci-color-red-dark;
    &:hover {
      color: $lvp-ci-color-red-light;
    }
  }

  .track-ctrl {
    .next-modes {
      cursor: pointer;
      svg.next-mode {
        pointer-events: none;
      }
    }
  }

  #background {
    position: absolute;
    display:none;
    object-fit: cover;
    height: 50vh;
    width: 50vw;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(1px);
    opacity: 0.5;
    z-index: -1;
  }

  .outer-container {
    display: none;
    //justify-content: center;
    //align-items: center;
    position: absolute;
    //max-height: 50vh;
  }

  .player-container {
    display: flex;
    flex-direction: column;
    flex-shrink:0!important;
    position: relative;
    width:100%;
    height: 100vh;

    @media screen and (min-width: 900px) and (max-width: 2000px) {
      width: 500px;
    }
    @media screen and (min-width: 2000px) {
      width: 800px;
    }

    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
    overflow: hidden;
    //justify-content: center;
    //align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 20px $lvp-ci-color-red-dark;

    &:hover {

      box-shadow: 0 0 20px $lvp-ci-color-red-light;
    }

    #thumbnail {
      position: absolute;
      //position: relative;
      display: block;
      object-fit: fill;
      //height: 20vh;
      //width: 100%;
      bottom: 0;
      top: 0;
      transition: 1s;
      z-index: 3;
    }

    #track-list {

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: auto;
      flex-wrap: nowrap;
      //padding: 0.5rem;
      //margin-top:9.2rem;


      height:calc(100vh - 250px);


      overflow: auto;
      scrollbar-gutter: stable;
      //height: 80vh;

      z-index:10;
      background-color: rgba(33, 37, 41, 0.8) !important;

      .no-tracks-display {
        margin: 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .track-item {
        flex-grow: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding:3px;
        margin-bottom:1px;
        cursor: pointer;
        position: relative;
        display: flex;
        height:64px;
        max-height: 64px;

        > * {
          pointer-events: none;
        }

        .ti-thumb {
          pointer-events: all!important;

          img {
            pointer-events: none!important;
          }
        }

        border-bottom: 1px solid #21252a;
        color: #fff;

        &.active {
          background: rgb(218,3,3);
          background: linear-gradient(90deg, rgba(218,3,3,0.3) 0%, rgba(121,9,9,0.3) 5%, rgba(121,9,9,0.3) 100%)!important;
          color: #fff!important;
        }

        &:hover {
          background: rgb(218,3,3);
          background: linear-gradient(90deg, rgba(218,3,3,0.7) 0%, rgba(121,9,9,0.7) 5%, rgba(121,9,9,0.7) 100%)!important;
          color: #fff!important;
        }

        .ti-thumb {
          display: flex;
          //background-color: #0a0a0a;
          justify-content: center;
          img.ti-thumb-img {
            width:60px;
          }
        }

        .ti-info {
          flex-grow: 1;
          display: grid;
          justify-content: flex-start;
          padding-left: 1rem;


          .ti-artist {
            font-family: "Lato", sans-serif;
            font-weight: bold;
            font-size: 0.8rem;
          }
          .ti-title {
            font-family: "Lato", sans-serif;
            font-size: 0.8rem;
          }
          .ti-divider {
            //width:20px;
          }
        }

        .ti-info-2 {
          .ti-duration-time {
            font-family: "Lato", sans-serif;
            font-size: 0.6rem;
            font-weight: 600;
            text-align: right;
          }
        }
        .ti-control {
          padding:10px;
        }
      }
    }

    #player-control {
      position: absolute;
      //margin-top:auto;
      //bottom:0px;
      height: 150px;
      //margin-bottom:2vh;
      width: 100%;
      //background: linear-gradient(90deg, rgb(145, 145, 145), rgb(220, 220, 220));
      z-index: 5004;
      bottom: 0;
      display: grid;
      grid-template-columns: 0.5fr 1.1fr 1fr 1fr;
      grid-template-rows: 85px 25px 25px;
      grid-template-areas:
    "one two two two three"
    "four four four four four"
    "five five five five five";
      column-gap: 10px;

      @media screen and (max-width: 800px) {
        grid-template-columns: 60px 80px 40px;
        grid-template-rows: 85px 25px 25px;
        grid-template-areas:
    "one two two two three"
    "four four four four four"
    "five five five five five";
        column-gap: 10px;
      }

      @media screen and (min-width: 800px) and (max-width: 3000px) {
        grid-template-columns: 0.5fr 1.1fr 1fr 1fr;
        grid-template-rows: 85px 25px 25px;
        grid-template-areas:
    "one two two two three"
    "four four four four four"
    "five five five five five";
        column-gap: 10px;
      }


      #play,
      #prev-track,
      #next-track {
        //filter: invert(1);
        cursor: pointer;
      }

      .play-pause {
        grid-area: one;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
          padding-left:1rem;
        }

        .fa-pause-circle {
          //filter: invert(1);
          cursor: pointer;
          display: none;
        }
      }

      .track-info {
        grid-area: two;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5%;
        margin-right: -1rem;
        /* border: 1px solid red; */
      }

      #track-artist {
        color: #f94c57;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        font-size: 1.125rem;
        text-shadow: 0 0 15px white;
        overflow: hidden;
      }

      #track-title {
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1rem;
        overflow: hidden;
      }

      .next-prev {
        grid-area: three;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding:5px;
        /* border: 1px solid red; */
        .divider {
          width:10px;
        }
      }

      .progress-bar {
        grid-area: four;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
        background-color: unset!important;
      }

      #progressBar {
        appearance: none;
        height: 5px;
        background: white!important;
        width: 90%;
        outline: none;
        border-radius: 30px;
      }

      #progressBar::-webkit-slider-thumb {
        appearance: none;
        height: 11px;
        width: 11px;
        outline: none;
        background: #f94c57;
        border-radius: 30px;
        cursor: pointer;
      }

      .track-time {
        grid-area: five;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        /* border: 1px solid red; */
      }

      #currentTime {
        font-family: "Lato", sans-serif;
        font-size: 1rem;
        color: white;
      }

      #durationTime {
        font-family: "Lato", sans-serif;
        font-size: 1rem;
        color: #f94c57;
        text-shadow: 0 0 15px white;
      }

      .marquee {
        max-width: 100vw; /* iOS braucht das */
        white-space: nowrap;
        overflow: hidden;
        /* hier evtl. noch font-size, color usw. */
        /* Optional: mouseover (oder Tipp auf dem Touchscreen) pausiert die Laufschrift */
        span {
          display: inline-block;
          padding-left: 105%; /* die zusätzlichen 5% erzeugen einen verzögerten Start und vermeiden so ein Ruckeln auf langsamen Seiten */
          animation: marquee 10s linear infinite;
          &:hover {
            animation-play-state: paused
          }
        }
      }

      /* Make it move */
      @keyframes marquee {
        0%   {transform: translate3d(0,0,0);}
        100% {transform: translate3d(-100%,0,0);}
      }


    }

  }












}