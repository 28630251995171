#mse-feedbacks {
  display:none;
  z-index: 900000002;
  opacity: 0.7;
  filter: Alpha(Opacity=70);
  background:none;
  position:fixed;
  width: 69vh;
  height:32vw;
  right: 0px;
  bottom:0px;
  pointer-events: none;
  vertical-align:bottom;
  overflow:auto;


  .mse-feedback {
    z-index: 900000003;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    min-width: 250px;
    min-height: 50px;
    margin: 10px;
    padding:10px;
    color: #000000;
    opacity: 0.7;
    filter: Alpha(Opacity=70);
    vertical-align:bottom;
    bottom:0px;
    background-image: linear-gradient(to bottom,#f9f9f9,#f2f2f2);
  }
}