.nav-item {
  i.fa-user {
    color: #d42a2a;
  }
}

.dropdown-menu {
  background-color: rgba(33,37,41, 0.9)!important;


  &.sidebar-account-dropdown {
    margin-left:2.4rem!important;
  }
  
  .dropdown-item {
    color: #fff;
    font-weight: 300;
    font-family: Open Sans, Arial, Helvetica, sans-serif !important;
  }

  .dropdown-item:focus, .dropdown-item:hover {
      //color: rgba(33,37,41, 0.9)!important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .nav-link2.active,
.nav-pills .show>.nav-link,
.nav-pills .show>.nav-link2
{
  color: #fff;
  background-color: #d42a2a;
}

.lvp-divider {
  flex-shrink: 0;
  width: 1.4rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
 //  font-weight: 600;
  color: rgba(253, 253, 253, 0.65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  //color: rgba(0, 0, 0, .85);
  //background-color: #d42a2a; //
  background: linear-gradient(90deg, #da030399 0%, #79090999 5% 100%); //
  color:rgba(255,255,255,0.85);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
  color:rgba(255,255,255,0.85)!important;
  background-color: #d42a2a;
  //background: linear-gradient(90deg, #da030399 0%, #79090999 5% 100%);
  //background-color: rgba(255,255,255,0.85);
  margin-right: 1rem;
  padding: 1rem 1.5rem 1rem 0.5rem;

}

.btn-toggle[aria-expanded="true"] {
  //color: rgba(0, 0, 0, .85);
  background-color: #d42a2a; //
  color:rgba(255,255,255,0.85)!important;

}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
  background-color: #d42a2a;
  background-color: unset!important;
  border-radius: 1rem 1rem 1rem 1rem;
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
  color:rgba(255,255,255,0.85)!important;
  i {
    padding-top: 0.25rem;
    padding-right: 0.5rem;
  }
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  //background-color: #d42a2a; //
  background: linear-gradient(90deg, #da03034d 0%, #7909094d 5% 100%) !important;;
  color:#fff;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }


a.nav-link {
    //padding: 0px!important;
     padding: 8px 8px 8px 8px !important;
    i {
      color: #fff;
    }
    &.active {
      i {
        color: #fff;
      }
    }
    &.border-bottom {
      border-bottom: 1px solid rgba(107,107,107,0.2)!important; //#6b6b6b
    }

}

a.nav-link2 {
  display:block;
  //padding: 0px!important;
  padding: 8px 8px 8px 8px !important;

  i {
    color: #fff;
  }
  &.active {
    i {
      color: #fff;
    }
  }
  &.border-bottom {
    border-bottom: 1px solid rgba(107,107,107,0.2)!important;
  }

}

.dropdown {
  &.border-top {
    border-top: 1px solid rgba(107,107,107,0.2)!important;
  }
}

.lvp-app-container2::before {
  content: "";
  background-image: url('../../mse/cdn/img/lordfox-wallpapers/lvp-wallpaper-trans30.png');
  background-size: cover;
  background-position: revert;
  background-color: #2d3136!important;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}


.sb-logo {
  padding-top:0.5rem!important;
}

nav.navbar {
  .nav-item {
    &:hover {
      background: rgba(218,3,3,0.6);
      background: linear-gradient(90deg, rgba(218,3,3,0.6) 0%, rgba(121,9,9,0.6) 5%, rgba(121,9,9,0.6) 100%);
    }
    .nav-link {
      &.active {
        background: linear-gradient(90deg, #da030399 0%, #79090999 5% 100%);
      }
    }
  }
}

.navbar-toggler {
  border:unset!important;
  border-radius: unset!important;
  background: unset;
  &:focus {
    box-shadow: unset!important;
    /*background: linear-gradient(90deg, rgba(218,3,3,1) 0%, rgba(121,9,9,1) 5%, rgba(121,9,9,1) 100%);*/
  }
}
.dropdown-toggle:after {
  vertical-align: 0.255em;
  content: "";
  border: 0.3em solid #0000;
  border-top-color: currentColor;
  background: rgb(218,3,3);
  background: linear-gradient(90deg, rgba(218,3,3,1) 0%, rgba(121,9,9,1) 5%, rgba(121,9,9,1) 100%);
  border-bottom: 0;
  margin-left: 0.255em;
  display: inline-block;
  color: rgb(218,3,3);
}

.dropdown-item:focus, .dropdown-item:hover {
  //background-color: #d42a2a!important;
  color:#fff!important;
  background: rgb(218,3,3);
  background: linear-gradient(90deg, rgba(218,3,3,0.6) 0%, rgba(121,9,9,0.6) 5%, rgba(121,9,9,0.6) 100%);
}