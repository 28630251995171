.drive-container {

  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 100%;
  width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  @include respond-to('xl') {
    width: calc(100vw - 40px);
  }

  img {
    .hide {
      display:none!important;
    }
  }

  .drive-selection {
    position: absolute;
    top:0;
    z-index:10001;
    margin-left: -1rem;
    margin-top: -1rem;
    height:5rem;
    background-color: $lvp-ci-color-dark;
    .wo-selection {
      display:none;
    }

    &[data-count="0"] {
      .on-selected {
        display: none!important;
      }
      .wo-selection {
        display:flex!important;
      }
    }

    .on-selected {
      display:flex;
      align-content: baseline;
      justify-content: space-between;

      padding:0.5rem;

      color: #fff;

      .status {
        flex-grow: 1;
        .entry-selected-count {
          margin-left:1rem;
        }
      }

      .controls{
        flex-grow:1;
        display:flex;
        justify-content: flex-end;
      }
    }



  }

  .drive-header {
    background-color: $lvp-ci-color-dark;
    position: sticky;
    top:0;
    padding: 1rem;
    z-index:10001;
    display:flex;
    justify-content: space-between;

    .drive-path-dir {
      flex-grow: 2;

      .title {
        padding: 0.75rem;
        i {
            color: $lvp-ci-color-red-dark;
            &:hover {
              color: $lvp-ci-color-red-light;
            }
        }
        h3 {
          color: #fff;
          font-size: 1.75rem;
          display: inline;
          font-family: Open Sans, Arial, Helvetica, sans-serif !important;
          font-weight: 300 !important;
        }
        
      }
      .directory {

      }
    }
  }



  .drive-listing {


    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .drive-path-controls {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      background-color: $lvp-ci-color-dark;
      button.dir-add {
        border-radius: 5px 5px 5px 5px!important;

      }
    }

    .listing-divider {
      margin-top: 1rem;
      padding-left:1rem;
      font-weight: 700;
    }

    .files-container {
      margin-bottom:1rem;

      .dir-entry {

        .entry-content {
          .entry-footer {
            display: flex;
            flex-direction: row;

            .entry-type {
              border-radius: 0!important;
              padding:0.5rem!important;
            }

            .entry-name {
              text-align: center;
              padding:0.5rem!important;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .dir-container {

      .dot {
        display: flex;
        .entry-content {
          display: flex;
          padding: 1rem;
          .entry-type {
            display: flex;
            justify-content: center!important;
            i {
              color: $lvp-ci-color-red-dark;
              &:hover {
                color: $lvp-ci-color-red-light;
              }
            }
          }
        }
      }

      .dir-entry {
        max-height: 3.2rem!important;

        .entry-preview {
          display:none;
        }

        .entry-content {
          min-height: unset!important;



        }

        .entry-footer {
          display: flex;
          flex-direction: row;

          .entry-type {
            border-radius: 0!important;
            padding:0.5rem!important;
          }

          .entry-name {
            text-align: center;
            padding:0.5rem!important;
          }
        }

      }
    }

    .files-container,
    .dir-container {
      width: calc(100vw - 40px);
      @include respond-to('xl') {
        width: calc(100vw - 60px);
      }

      display: grid;

      grid-template-columns: 1fr;
      grid-template-rows: auto;
      //grid-gap: 1rem;

      @include respond-to('s') {
        grid-template-columns: 1fr 1fr;
      }

      @include respond-to('m') {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include respond-to('l') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include respond-to('xl') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include respond-to('xxl') {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }




      .dir-entry {

        position: relative;
        display: flex;
        flex-direction: row;

        background-color: $lvp-ci-color-dark;
        cursor: pointer;

        border:2px solid $lvp-ci-color-dark;

        &.fullscreen {
          display: flex;
          width: calc(100vw - 40px);
          height: 100vh;
          position: fixed;
          z-index: 10002;
          top: 0;
          bottom: 0;
          left: 40px;
          right: 0;
        }

        &.selected {
          background-color: $lvp-ci-color-red-dark;
          border: 2px solid $lvp-ci-color-red-dark!important;
          .entry-navigation {
            background-color: $lvp-ci-color-red-dark!important;
          }
        }

        &[data-perms="0777"] {
          .entry-type {
            color: rgba(34, 217, 41, 1) !important;
          }
        }

        &[data-perms="0755"] {
          .entry-type {
            color: $lvp-ci-color-red-dark!important;
          }
        }

        &[data-perms="0644"] {
          .entry-type {
            color: $lvp-ci-color-red-dark !important;
          }
        }

        .entry-thumb {
          pointer-events: none;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: stretch;
          overflow: hidden;
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          z-index: 100;

          img {
            max-width: 100%;
            object-fit: cover;
          }
        }

        &.file,
        &.dir {
          .entry-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-height: 240px;
            z-index:101;
            justify-content: flex-end;

            .entry-preview {
              flex-grow: 6;

              img {
                display: none;
                width: 100%;
              }
            }

            .entry-footer {
              display:flex;
              justify-content: flex-end;
              .entry-type {
                display: inline-flex;
                font-size: 200%;
                background-color: rgba(0,0,0,0.6);
                padding: 0.5rem 1rem 0.2rem 1rem;
                border-radius: 0 1rem 0 0;

              }

              .entry-name {

                display:block;
                width:100%;
                font-size: 1em;
                text-overflow: ellipsis;
                overflow:hidden;
                background-color: rgba(0,0,0,0.6);
                padding: 0.2rem 0 0.2rem 1rem;
              }
            }


          }

          .entry-navigation {
            flex-grow: 1;
            z-index:101;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            max-width: 60px;
            background-color: rgba(0,0,0,0.6);
            .entry-nav {

              .entry-nav-head {
                font-size:0.6rem;
                text-align: center;
                color:#eee;
              }
              .fa-bars {
                color:#fff;
              }
              .dropdown-toggle {
                z-index:9008;
                padding:1rem;
              }
              .dropdown-toggle:after {
                display:none!important;
              }
            }
          }

        }



        .entry-del {
          cursor: pointer;
        }

      }
    }
  }

}