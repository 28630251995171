.input-replay-preview {
  width:100%;
  flex-grow: 1;
  position: relative;
  padding: 0.25rem;

  margin-bottom: 1rem;

  .message-replay-preview {

    background-color: #2c3034;
    border-radius: 5px;
    padding: 0.25rem;
    position: relative;
    display: flex;
    flex-direction: row;
    .replay-body {
      flex-grow: 3;
      display:flex;
      flex-direction: column;
      .replay-author {
        font-size: 1rem;
      }
      .replay-content {
        font-size:0.8rem;
        img {
          max-height: 64px;
          max-width:64px;
        }
      }
    }
    .replay-cancel {
      position: relative;
      flex-grow: 1;
      justify-content: center;
      align-content: baseline;
      min-width:64px;
      cursor: pointer;
      pointer-events: all;
      i {
        pointer-events: none;
        font-size: 1.5rem;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0.6;
      }
    }
  }
}

.input-container {

  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-around;
  width:100%;

  .input-controls {
    display:flex;
    flex-grow: 1;
    //justify-content: space-around;
    gap:10px;

    .input-button {
      position: relative;
      display:flex;
      min-width: 2rem;
      //border:1px solid rgba(205, 5, 5, 0.8);
      border-radius: 25% 25%;
      text-align: center;

      &:hover {
        background-color: #2c3034;
      }

      .center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      i {
        color:#fff;
      }
    }

  }

  .input-text {
    display:flex;
    flex-grow: 4;
    position: relative;
    background-color: #2c3034;
    border-radius: 5px;
    width:100%;
    margin-left:1rem;
    margin-right:1rem;



    img {
      width: 100%;
      max-width: 360px;
      height: auto;
    }

    .input-text-placeholder {
      position: absolute;
      z-index: 100002;
      padding:5px;
      //display: none;
    }

    .conversation-input-text {
      z-index: 100003;
      display:block;
      flex-grow: 1;
      border-radius: 5px;
      position: relative;
      overflow:auto;
      max-height:7rem;
      padding:0.5rem;

      &:focus {
        background-color: #2c3034;
      }
    }

  }

  .input-buttons {
    display:flex;
    flex-grow: 1;

  }



}