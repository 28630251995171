
.conversations-container {

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: auto;
  flex-wrap: nowrap;
  height:calc(100vh - 250px);
  overflow: auto;
  scrollbar-gutter: stable;
  z-index:10;
  background-color: rgba(33, 37, 41, 0.8) !important;

  .conversations-list {

    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 100px;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-anchor: auto;
    scrollbar-gutter: stable;

    * {
      overflow-anchor: none;
    }

    #conversation-anchor {
      overflow-anchor: auto;
      height: 1px;
    }

    .list-item {

      flex-grow: 1;




      margin-left: 0.2rem;
      margin-right: 0.2rem;
      padding:3px;
      margin-bottom:1px;
      cursor: pointer;
      position: relative;
      display: flex;
      height:64px;
      max-height: 64px;
      justify-content: space-between;

      flex-direction: row;
      align-items: stretch;


      border-bottom:1px solid #912d2f;
      color:#fff;
      border-radius: 5px;
      &:hover {
        background: #da0303;
        background: linear-gradient(90deg, #da0303 0%, #790909 5% 100%) !important;

      }

      > * {
        pointer-events: none;
      }

      .item-info {
        font-size: 1.2rem;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap:1rem;

        justify-content: space-between;

        .conversation-image {
          .img-rounded {
            max-width: 48px;
          }
        }



        .conversation-item-info {
          display: flex;
          flex-direction: column;
          flex-grow: 4;

          .conversation-name {
            display: flex;
            flex-grow: 4;
          }

          .conversation-last-message {
            flex-grow: 1;

            .conversation-message-preview {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: stretch;
              align-content: stretch;
              position: relative;
              width: 100%;


              .author-display {
                display:flex;
                font-weight: bold;
                font-size: 0.8rem;
                padding-right: 5px;
              }
              .message-content {
                display:flex;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 22px;
                flex-grow: 1;
                font-size: 0.8rem;
                flex-shrink: 3;

                img {
                  display:none;
                }

              }

              .message-time {
                position: absolute;
                right: 5px;
                top:5px;
                font-size: 0.7rem;
              }
            }

          }
        }


        .conversation-pin {
          position: absolute;
          top:5px;
          right:1rem;
          i {
            font-size: 0.8rem;
          }
        }



      }
      .item-info-short {}
    }
  }

}