* {
  margin:0;
  padding:0;
}
html,
body {
  border:0px;
  margin: 0px;
  padding:0px;
  height: 100%;
  //overflow-y: scroll;

}
@media (pointer: coarse)  {
  /* mobile device */
}

@media (pointer: fine), (pointer: none) {
  /* desktop */
  ::-webkit-scrollbar {
    //width: 18px;
    background-color: #21252a;
    //background-color: rgba(45,49,54,0.8)!important; //#2d3136
    //background-color: transparent;
    //background-color: rgba(45,49,54,0.8)!important; //#2d3136
    //scrollbar-gutter: stable;
  }
  ::-webkit-scrollbar-button {
    background-color: #21252a;
    //background-color: rgba(45,49,54,0.8)!important; //#2d3136
  }
}

@media (pointer: fine) and (any-pointer: coarse) {
  /* touch desktop */
  ::-webkit-scrollbar {
    //width: 18px;
    background-color: #21252a;
    //background-color: transparent;
    //background-color: rgba(45,49,54,0.8)!important; //#2d3136
    //scrollbar-gutter: stable;
  }
}


//::-webkit-scrollbar-button {
  //background-color: #21252a;
//  background-color: rgba(45,49,54,0.8)!important; //#2d3136
//}

::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {

}
::-webkit-resizer {

}

::-webkit-scrollbar-thumb {
  background-color: #8f2d2f;
  border-radius: 20px;
  border: 7px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d22a2b;
}

::-webkit-scrollbar-corner {

}


#OSBViewer2 {
  position: relative;
  z-index:1998;
  //width:100vw;
  width:100%;
  //height:calc(100vh - 59px);
  height:100vh;
}

.box {
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
}

main {
  z-index: 1999;
  position: relative;
  background: transparent;
  background-color:unset!important;
  //background-color: rgba(45,49,54,0.8)!important;
  //pointer-events:all;

  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  //min-height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  //scrollbar-gutter: stable;

  .navbar {
    z-index: 3000!important;
    padding-left: 1rem;
    padding-right:1rem;
    position: absolute!important;
    right:0px;
    bottom:0px;
    left:0px;
    min-height: 3rem;
    display: flex;
    justify-content: flex-start;

    .navbar-collapse {
      height:fit-content;
    }
    
  }

  .sub-sidebar {
    //width: calc(100vw - 40px); // - lvp-sidebar width
    //max-width:calc(23vw - 80px);
    //min-width:280px;
    width: 320px;
    

    padding:0.5rem;
    border-left: 1px solid #6b6b6b33 !important;
    .scrollarea {
      ul li {
        display: flex;
        justify-items: stretch;
        flex-flow: column;
        border-bottom: 1px solid #6b6b6b33 !important;
        button {
          flex-grow: 1;

        }
      }
    }

    .border-bottom {
      border-bottom: 1px solid #6b6b6b33 !important;
    }

    .border-top {
      border-top: 1px solid #6b6b6b33 !important;
    }

    ul.btn-toggle-nav {

      li {
        a {
          padding:1rem 2rem 1rem 1rem;
          margin-left: 3rem;
        }

        border-bottom: 1px solid #6b6b6b33 !important;
        &:last-child {
          border-bottom: 0!important;
        }
      }


    }

  }


  .lvp-sidebar {

    z-index: 3001;

    pointer-events: all!important;

    a {
      i {
        pointer-events: none;
      }
    }
  }

  .lvp-sidebar-right {
    display:none!important;
  }

  .sub-sidebar {
    z-index: 3000;

    pointer-events: all!important;

    a {
      i {
        pointer-events: none;
      }
    }
  }

  #overlay-content {

    @media screen and (max-width: 800px) {
      width: calc(100vw - 2.5rem);
    }
  }

  .cx-container > * {
    pointer-events: all;
  }
  .cx-container {
    display:flex;
    background: transparent;
    background-color:unset!important;
    //height: calc(100vh - 59px);

    //background-color: rgba(45,49,54,0.5);

    overflow-x: hidden;
    overflow-y: auto;
    //max-height: 50vh;
    background-image: url('../../mse/cdn/img/lordfox-wallpapers/lvp-wallpaper-trans5.png');
    background-size: cover;
    background-position: revert;
    // background-color: #2d3136!important;
    padding-left:0px!important;
    padding-right: 0px!important;
    //width:100vw;
    width:100%;

    @media (pointer: coarse)  {
      scrollbar-gutter: stable;
    }

    #main {
      //width:100vw;
      height:100vh;
      position: relative;
    }


    #content {

      &.fullscreen {

        .lvp-app-container {
          background-color: rgba(45,49,54,1)!important;
          .lvp-app-content {
            background-color: rgba(45,49,54,1)!important;
            position: absolute!important;
            width:100vw;

            height:100vh;
            top:0px;
            left:0px;
            bottom:0px;
            right:0px;
            z-index:10000;
            padding:0px;
            border-radius: 0!important;
            .audio-player-container {
              height:100vh;
              width:100vw;
              .player-container {
                width:100vw;
                height:100vh;
                border-radius: 0!important;
              }
            }
          }
        }
      }


    }
    .lvp-app-container {

      height: calc(100vh - 54px); // - TopNavigation

      @media screen and (min-width: 800px) and (max-width: 3000px) {
        //padding-top: var(--bs-gutter-x, .75rem);
      }
      @media screen and (max-width: 800px){
        padding:unset!important;
        //padding-top: var(--bs-gutter-x, .75rem);
      }

      .lvp-app-content {
        color:#eee;
        background-color: rgba(45,49,54,0.8)!important; //#2d3136
        height:100%;
        //box-shadow: 0px 0px 30px 5px rgba(140,140,140,0.5);

        @media screen and (min-width: 800px) and (max-width: 3000px) {
          //border-radius: 5px;

        }

        //box-shadow: 0 0 20px #f94c57;
        //@media screen and (min-width: 800px) and (max-width: 3000px) {
        padding: 0;

        @include respond-to('xl') {
          //padding: 1rem 1rem 1rem 1rem;
        }

        //}
        .btn-danger {
          background: rgb(218,3,3);
          background: linear-gradient(90deg, rgba(218,3,3,1) 0%, rgba(121,9,9,1) 5%, rgba(121,9,9,1) 100%)!important;
        }
      }

      .lvp-app-footer {
        position: absolute;
        z-index:2003;
        //width:90vw;
        width:100%;
        bottom: 15px;
display: none;
        flex-direction: row;
        padding:10px;
        justify-content: space-between;
        flex-grow: 1;
        justify-items: center;
        align-items: baseline;
        font-size: 0.7rem;

        .lvp-copyright {
          font-size:0.8rem;
          color: rgba(255,255,255,.65) !important;
          a {
            color: rgba(255,255,255,.65) !important;
          }
        }

        .lvp-social-links {
          list-style: none;
          display:flex;
          li {
            color: #ffffff;
            display: inline-flex;
            padding-left:0.2rem;
            padding-right: 0.2rem;
            svg {
              color: rgba(255,255,255,.55);
            }
          }
        }
      }

    }



  }


}






.lvp-nice-bg {
  background-color: transparentize($lvp-ci-color-darkgrey, 0.1);
}

.card {
  background-color: transparentize($lvp-ci-color-darkgrey, 0.9);
  .card-body {
    background-color: transparentize($lvp-ci-color-darkgrey, 0.9);
    .list-group-item {
      background-color: transparentize($lvp-ci-color-dark, 0.9);
      color:#fff!important;
    }
  }
}

small a {
  font-size: 0.5em;
}

.x1 {
  z-index: 2000;
  position: relative;
  height:fit-content;
}


.ce-popover__item-icon {
  color: #0a0a0a;
}

.ce-popover__item-label {
  color: #0a0a0a;
}

.tc-popover__item {
  color: #0a0a0a;
}

.ce-inline-toolbar {
  color: #0a0a0a;
}
.cdx-settings-button--active,
.ce-toolbar__settings-btn,
.ce-toolbar__plus {
  color: rgb(218,3,3);
}