

.osbv-ui-container {

  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.15rem !important;

  .osbv-ui-panel-docks {
    .osbv-ui-panel-dock {
      .osbv-ui-panel {



      .osbv-ui-panel-header {}
        .osbv-ui-panel-content {}
      }
    }
  }
}


// Panel Dock #1
.ui-pd-1 {

  .ui-p-2 {
    display:none;
  }

  .ui-p-1 {

    background-color: #333333;
    text-align: center;
    grid-template-rows: auto 1fr;

    .osbv-ui-panel-header {
      height: 30px;
      background-color: #222222;
      grid-template-columns: 30px 1fr 50px;
      text-align: center;

      .icon_deprecated {
        grid-column: 1;
        width: 24px;
        height: 24px;
        left: 0;
        display: flex;
        align-self: center;
        justify-self: center;

      }

      .logo {
        //background-image: url('http://osd.lordfox.de:8081/mse/cdn/assets/img/OpenSimulator/OpenSimulator_logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        width:100%;
        height:100%;
      }

      .commands {
        grid-column: 3;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
      }

      .title {
        display:none;

        height:30px;
        grid-column: 2;
        font-size: 1em;
        font-family: Arial;
        color: white;
        display: grid;
        align-items: center;
        text-align: center;
      }
    }

    .osbv-ui-panel-content {
      display:none;
    }
  }
}

// Panel.Dock #2
.ui-pd-2 {


  .ui-p-3 {
    // Viewer
    .osbv-ui-panel-content {

    }

  }

}
