#content {
    width:100%;
    //height:100%;
    padding:0px;
    margin: 0px;
    //background-color: #2d3136;
    position: absolute;
    z-index: 2001;
}

header {
    z-index: 20000;
}

body {
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif!important;
    font-weight: 300;
}

.wallets-container {
    padding-right: 0px;
    padding-left: 0px;
    .card {
        //background-image: url('/mse/cdn/img/lordfox-wallpapers/lvp-wallpaper-trans5.png');
        //background-size: cover;
        //background-position: center;
        //background-color: unset!important;
        .card-body {
            color:#000!important;
        }
    }
}


