$inventory-button-background: #000000;
$inventory-color: #fffffff;
$inventory-content-background: #000000;
$inventory-input-background: #ffffff;
$inventory-border: 1 px solid;
$inventory-content-item-background: #ffffff;

#app_inventory {
  position: fixed;
  top: 155px;
  z-index: 2000;
  right: 0;

  box-shadow: 1rem -1rem 1rem 0 rgba(0,0,0,.15)!important;
  border: 1px solid rgba(0,0,0,.125)!important;
  color: #8b91a0;

  .button {
    background: $inventory-button-background;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: $inventory-color;
    display: block;
    height: 30px;
    margin-left: -30px;
    outline: 0 none !important;
    position: absolute;
    transition: all 0.2s ease 0s;
    width: 30px;
    text-decoration: none;
    i {
      display: block;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      width: 30px;
    }
  }

  .content {
    display: none;
    /*background: @inventory-content-background;*/
    background-image: linear-gradient(to bottom,#f3f3f3,#e2e2e2);
    background-color: rgba(50, 118, 176,0.6);
    border-bottom-left-radius: 5px;
    color: $inventory-color;
    padding: 0 5px 0 10px;
    width: 25vw;
    min-height: 80vh;

    #inventory-switch {
      float: right;
      margin-bottom: 2px;
      position: absolute;
      right: 10px;
      top: 4px;

      span {
        display: inline;
        margin: 0;

        font-size: 11px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .mse-widget-pager {
      background: $inventory-content-background;

      .dt-toolbar-footer {
        background: none;
        border-top: none;
        box-shadow: none;
      }
    }

    #inventory-edit {
      margin-bottom: 5px;

      button {
        border-radius: 3px;
        padding: 0 2px 0 5px;
      }
    }

    .pager {
      margin: 5px 0;
      .mse-fieldset-pager {
        padding: 2px 10px;
        .results {
          float: none;
        }
      }
    }

    label {
      margin-bottom: 10px;
      padding: 0 5px;
      font-size: 16px;
      display: block;
    }

    span {
      display: block;
      margin-bottom: 10px;
    }

    input, select {
      background: $inventory-input-background;
      border: $inventory-border;
      border-radius: 3px;
      color: $inventory-color;
      &.inventory-search-query {
        width: 100%;
      }
    }

    .inventory-items {
      list-style-type: none;
      max-height: 300px;
      padding: 0px;
      overflow: hidden;
      overflow-y:scroll;

      .inventory-item {
        background: $inventory-content-item-background;
        border-radius: 3px;
        margin: 0 5px 1px 0;
        cursor: pointer;

        padding:5px 5px 5px 10px;

        span {
          margin:0px;

          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
