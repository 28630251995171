$breakpoints: (
        's':  ( min-width:  576px ),
        'm':  ( min-width:  768px ),
        'l': ( min-width:  992px ),
        'xl':  ( min-width: 1200px ),
        'xxl': (min-width: 2000px)
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}