
@media screen and (min-width: 800px) and (max-width: 3000px){

  html {
    //zoom:150%!important;
  }

}

body {
  background-color: #2d3136;


  color: #9B9B9B;

  h1 {
    color:#9B9B9B!important;
  }

  footer {
    display:none;
  }

}