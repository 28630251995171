@import "messenger/conversations";
@import "messenger/conversation";
@import "messenger/conversation.input";
@import "messenger/message";

#overlay-messenger {

  .messenger-wrap {
    display: block;
    height:100%;
  }

  ul.navbar-nav i {
    font-size: 1.5rem;
  }

  a.dropdown-toggle {

    &:after {
      display: none;
    }
  }

  //position: absolute;
  //right:0px;

  @media screen and (max-width: 800px) {
    width: calc(100vw - 2.5rem);
  }

  .messenger-container {

    height: 100%;

    @media screen and (min-width: 900px) and (max-width: 2000px) {
      width: 500px;
    }
    @media screen and (min-width: 2000px) {
      width: 800px;
    }

    h3 {
      color: #fff;
      font-size: 1.75rem;
      display: inline;
      font-family: Open Sans, Arial, Helvetica, sans-serif !important;
      font-weight: 300 !important;
    }

   // width:25vw;
    .messenger-header {

      z-index: 11;
      justify-content: space-between;
      padding: 1.75rem;
      display: flex;

      i {
        color: #912d2f;
      }
      h3 {
        color: #fff;
        font-size: 1.75rem;
        display: inline;
        font-family: Open Sans, Arial, Helvetica, sans-serif !important;
        font-weight: 300 !important;
      }
    }

    .chat-contacts {
      .chat-contact {
        > * {
          pointer-events: none;
        }
      }
    }

    .messenger-content-wrapper {
      //padding: 0 1.75rem 0 0;
      height:100%;

    }

  }
}