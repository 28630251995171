#OSBViewer2 {
  position: relative;

  .viewer-loading-screen {
    z-index: 5000;
    pointer-events: none;
    position: absolute;
    top:58px;
    width:100%;
    height: calc(100% - 58px);
    color:#000;
    overflow:hidden;
    padding:2rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
