.wallet-buy-step {
  display: none;
  .list-group-item {
    background-color: transparentize($lvp-ci-color-darkgrey, 0.4);
  }
}



input:disabled {
  color: red;
}





.wallet-nav-display {
  position: fixed;
  right:2rem;
  &.dropstart {
    .dropdown-toggle::before {
      display:none;
    }
  }
  .lvp-wallet-display {
    background-color: transparentize($lvp-ci-color-darkgrey, 0.4);
    padding:10px;
    border-radius: 5px;

    &:hover {
      background-color: $lvp-ci-color-darkgrey;
    }

    .wallet-head {

      font-size: 0.8em;
    }
    .wallet-name {
      font-size: 0.8em;
      color:$lvp-ci-color-lightgrey;
    }
    .wallet-amount {
      font-size: 0.8em;
      color:$lvp-ci-color-lightgrey;
      .currency {
        color:$lvp-ci-color-red-light;
        font-weight: 400;
      }
    }
  }
}
