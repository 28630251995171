.paste-board-container {

  .paste-area {
    -webkit-user-select: auto !important;

    &[contenteditable=true]:empty:before{
      content: attr(placeholder);
      pointer-events: none;
      color:#FAFBFC!important;
      text-align: center;
      font-size: 1em;
      display: block; /* For Firefox */
    }

  }

  [contenteditable] {
    outline: 0 solid transparent;
  }



  display:flex;
  flex-basis: available;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  height:calc( 100vh - 54px);
  overflow: hidden;

  background-color: rgba(33, 37, 41, 0.8) !important;

  .paste-board-header {
    height:5rem;

  }

  .paste-board-content {
    display:flex;
    flex-grow: 1;
    border:0;
    background-color: rgba(255, 255,255, 0.2);
    /*min-height: calc(100vh - 200px);*/
    overflow-y: auto;
    overflow-x: hidden;

    .images {
      display:flex;
      flex-direction: column;
      image {
        flex-grow: 1;
      }
    }
  }

  .paste-board-footer {
    padding:1rem;
    font-weight: 700;
    height:5rem;
    max-height:10rem;
    overflow:auto;
  }
}