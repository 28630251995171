$ui-bg-default: rgba(33,37,41,0.5);
$ui-panel-toggle-icon-color: #D42A2A;
.ui-panel {

  background: $ui-bg-default;

  .ui-panel-toggle {

    color: $ui-panel-toggle-icon-color;
    position: absolute;
    //right:-32px;
    left:0px;
    top:0px;
    width:32px;
    height:32px;
    align-content: center;
    background: $ui-bg-default;
    cursor: pointer;
    //margin-left: 200px;

    i {
      align-self: center;
      font-size:1em;

      margin: 0.4em 0.3em 0.4em 0.3em;
    }

  }

  .ui-panel-docks {}

  .panel-container {

    margin-bottom: 20px;

      .panel-header {

        margin-bottom: 10px;

        .icon {
          min-width: 25%;
          i {
            font-size: 1.4em;
          }
        }

        .title {
          width: 50%;
          font-size: 1.3em;
        }

        .cmd {
          min-width: 25%;
        }
      }

      margin:10px;
      padding:0px 10px 30px 10px;

      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
      border-radius: 5px 5px 5px 5px;
  }

  &.panel-left {
    z-index: 9999999998;
    position:fixed;

    .panel-container {
      width:100%;
      background: $ui-bg-default;
      height: 100%;
    }

  }

}