.lvp-app-container {
  color:#fff;
  .table {
    color: #fff;
  }
  h1,h2,h3 {
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
    font-weight: 300;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.75rem;
  }
}

#lvp-login-user-form input {
  color: #000;
}
#lvp-login-user-form input[type=submit] {
  color:#fff!important;
}
.input-v-msg {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-wrapper {

  border-bottom: 1px solid rgba(255, 255, 225, 0.15);
  margin-top: 5px;
}

.pass-vis {
  padding:0px!important;
}

.alert {
  padding:5px!important;
  margin: 0px!important;
}

.v-pass-count-display, .v-name-count-display {
  font-size: 0.8em;
  text-align: right;
}

.v-pass-notice-display {
  font-size: 0.8em;
}

h2.subtitle {
  margin:0px;
}

.wallet-container small {
  font-size: 0.6em;
}

/*
default styling for the span within
the table td's on screens wider than
800px these are hidden and only made
visible when the media query hits 800px
*/

table.x11 td span {
  background: #eee;
  color: dimgrey;
  display: none;
  font-size: 11px;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  left: 0;
}

@media(max-width: 800px) {
  table.x11 tr th {
    /* hide the table headers when on smaller screens */
    left: -9999px;
    position: absolute;
    visibility: hidden;
  }
  table.x11 tr {
    /* reformat the table rows */
    border-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  table.x11 td {
    /* reformat the table columns */
    border: 1px solid dimgrey;
    margin: 0 -1px -1px 0;
    position: relative;
    width: 50%;
  }
  table.x11 td p {
    /* reformat the table cell values */
    font-size: 15px !important;
    margin: 0;
    padding: 25px 0 0 0 !important;
  }
  table.x11 td .btn {
    /* reformat any buttons within the table */
    font-size: 15px !important;
  }
  table.x11 td span {
    display: block;
  }
}

#lvp-login-user-form input {
  color: #000;
}
#lvp-login-user-form input[type=submit] {
  color:#fff!important;
}
.input-v-msg {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-wrapper {

  border-bottom: 1px solid rgba(255, 255, 225, 0.15);
  margin-top: 5px;
}

.pass-vis {
  padding:0px!important;
}

.alert {
  padding:5px!important;
  margin: 0px!important;
}

.v-pass-count-display, .v-name-count-display {
  font-size: 0.8em;
  text-align: right;
}

.v-pass-notice-display {
  font-size: 0.8em;
}

.input-v-msg {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-wrapper {

  border-bottom: 1px solid rgba(255, 255, 225, 0.15);
  margin-top: 5px;
}

.pass-vis {
  padding:0px!important;
}

.alert {
  padding:5px!important;
  margin: 0px!important;
}

.v-pass-count-display, .v-name-count-display {
  font-size: 0.8em;
  text-align: right;
}

.v-pass-notice-display {
  font-size: 0.8em;
}

#lvp-change-pass-user-form input {
  color: #000;
}
#lvp-change-pass-user-form input[type=submit] {
  color:#fff!important;
}
.input-v-msg {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-wrapper {

  border-bottom: 1px solid rgba(255, 255, 225, 0.15);
  margin-top: 5px;
}

.pass-vis {
  padding:0px!important;
}

.alert {
  padding:5px!important;
  margin: 0px!important;
}

.v-pass-count-display, .v-name-count-display {
  font-size: 0.8em;
  text-align: right;
}

.v-pass-notice-display {
  font-size: 0.8em;
}

.input-v-msg {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-wrapper {

  border-bottom: 1px solid rgba(255, 255, 225, 0.15);
  margin-top: 5px;
}

.pass-vis {
  padding:0px!important;
}

.alert {
  padding:5px!important;
  margin: 0px!important;
}

.v-pass-count-display, .v-name-count-display {
  font-size: 0.8em;
  text-align: right;
}

.v-pass-notice-display {
  font-size: 0.8em;
}

.table-account-info td {
  padding:5px;
}

.nk-social-links {
  list-style: none;
  display:flex;
  li {
    color: #ffffff;
    display: inline-flex;
    svg {
      color: rgba(255,255,255,.55);
    }
  }
}