body, html {width: 100%; height: 100%; margin: 0; padding: 0}
.x23-row-container {display: flex; width: 100%; height: 100%; flex-direction: column; background-color: blue; overflow: hidden;}
.x23-first-row {background-color: #2d3136; }
.x23-second-row { flex-grow: 1; border: none; margin: 0; padding: 0; }
img.x2-app-logo {
  max-height:400px;
}
.fade-in {
  animation: fadeIn ease 8s;
  -webkit-animation: fadeIn ease 8s;
  -moz-animation: fadeIn ease 8s;
  -o-animation: fadeIn ease 8s;
  -ms-animation: fadeIn ease 8s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

iframe.x2-app-frame {
  background-color: #9B9D9E;
  width:100%;
  max-height:512px;
  min-height: 512px;
  position:relative;
  top:50px;
  left: 0px;
  right:0px;
  bottom:50px;
  border:0;
  margin:0px;
  padding: 0px;

  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.15rem !important;
}

.content-inner-wrapper {

}



#OSBViewer {
  z-index: 900;
  position: relative;
  display:block;
  width:100%;
  height:288px!important;
}
canvas#viewer {
  display:block;
  z-index:901;
  border: 0px;
  border:unset!important;
  outline:0;
}

* {
  margin: 0;
  padding: 0;
}