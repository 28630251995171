.animated {
  animation-fill-mode: both;
  animation-duration: .6s;
}

.fadeIn {
  animation-name: fadeIn;
}

.login-v5 {
  background: rgba(33,37,41,.4);
  padding: 1rem;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #ccc;

  input {
    padding: 0.2em!important;
    margin-bottom: 1em!important;
    font-size: 2rem!important;
    color: rgba(33,37,41,.4)!important;
  }

  .login-title {
    margin-bottom: 1em!important;
  }

  .btn-success {
    background-color: #912D2F;
    border-color:#912D2F;
    padding-left: 200px;
    padding-right: 200px;
  }

  .btn-success:hover {
    background-color: #D42A2A;
    border-color:#912D2F;
  }

}


