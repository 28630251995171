.tabs{
  width: 100%;
  display: block;
  margin: 0px auto;
  position: relative;
}

.tabs .tab{
  float: left;
  display: block;
}

.tabs .tab>input[type="radio"] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.tabs .tab>label {
  display: block;
  padding: 6px 21px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  color: #FFF;
  background: #D42A2A;

  i {
    padding-right: 5px;
  }
}

.tabs .content {
  z-index: 0;/* or display: none; */
  overflow: hidden;
  width: 100%;
  padding: 0px;
  position: absolute;
 // top: 27px;
  left: 0;
  background: #212529!important;;
  color: #DFDFDF;

  opacity:0;
  transition: opacity 400ms ease-out;
}

.tabs>.tab>[id^="tab"]:checked + label {
  top: 0;
  background: #212529 !important;;
  color: #F5F5F5;
}

.tabs>.tab>[id^="tab"]:checked ~ [id^="tab-content"] {
  z-index: 1;/* or display: block; */

  opacity: 1;
  transition: opacity 400ms ease-out;
}

