.conversation-container {

  .conversation-messages {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 100px;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-anchor: auto;
    scrollbar-gutter: stable;
    z-index:10;
    background-color: rgba(33, 37, 41, 0.8) !important;

    .messages {
      overflow-anchor: auto;

      * {
        overflow-anchor: none;
      }

      .message-top {
        min-height:32px;
      }

      .message-bottom {
        min-height:32px;
      }

      #msg-anchor {
        overflow-anchor: auto;
        height: 1px;
      }
      .message {
        flex-grow: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding:3px;
        margin-bottom:0.5rem;

        position: relative;
        display: flex;
        flex-direction: row;
        //height:64px;
        //max-height: 64px;

        .content {
          //background-color: #6C8EA4;
          background-color: rgba(33, 37, 41, 0.8);
          border-radius: 16px 16px 16px 0;
          border: 1px solid rgba(205, 5, 5, 0.8);
          padding: 0.5rem 0.5rem 1.5rem 0.5rem;
          color:#fff;
          display: flex;
          flex-direction: column;
          min-width: 40%;
          position: relative;

          img.author {
            max-width: 24px;
          }

          .message-nav {
            position:absolute;
            right:1rem;
            top:0;
            i {
              font-size: 1rem!important;
            }
          }

          .replay-display {
            flex-grow: 1;
            position: relative;
            //background-color: #0a53be;
            //background-color: #912d2f;
            background: #da0303;
            background: linear-gradient(90deg, #da0303 0%, #790909 5% 100%) !important;
            padding:0.3rem 0.5rem 0.5rem 1.2rem;
            border-radius: 5px 5px 5px 5px;
            border-left:5px solid #0a0a0a;
            cursor: pointer;

            > * {
              pointer-events: none;
            }
            .replay-author {
              font-size: 0.8rem;
            }

            .replay-content {
              padding:0.1rem 0 0 0.5rem;
              img {
                max-width: 64px;
                max-height: 64px;
              }
            }
          }

          .author-display {
            display:block;
            font-weight: bold;
            font-size: 1rem;
            padding-top:1rem;
          }
          .message-inner {
            display: flex;
            //padding-left: 0.5rem;
            padding-top: 0.5rem;

            img.joypixels {
              max-width: 24px;

            }

            .msg-image-format {
              display: flex;
              flex-direction: column;

              .msg-images {
                flex-grow: 1;
                display:flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: stretch;
                justify-items: baseline;
                gap:5px;

                .msg-image-wrap {
                  flex-grow: 1;

                  img {
                    border-radius: 5px;
                    //width: 100%;
                    //max-width: 360px;
                    height: auto;
                    object-fit: cover;
                  }
                }
              }

              .msg-content {
                flex-grow: 1;
              }
            }



            img {
              width: 100%;
              //max-width: 360px;
              height: auto;
            }

          }
          .message-time {
            position: absolute;
            bottom: 5px;
            right:45px;
            display: inline;
            font-size: 0.8rem;
          }
          .message-status {
            position: absolute;
            bottom: 5px;
            right:8px;
            display: inline;
            font-size: 0.8rem;
            //background-color: #0a53be;
            padding-left:5px;
            padding-right:5px;
            border-radius: 5px;
            i {
              font-size: 0.8rem;
              margin: -2px;
            }
          }
        }

        &.me {

          flex-direction: row-reverse;
          .content {
            border: 1px solid #443f56;
            border-radius: 16px 16px 0 16px;
            display: inline-flex;
            padding: 0.5rem 0.5rem 1.5rem 0.5rem;
            background-color: #5b5377;
            color:#fff;
          }
        }

        //border:1px solid #eee;
      }


    }





  }

}