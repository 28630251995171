
.img-rounded {
  border-radius: 50%;
}

.conversation-container  {

  display:flex;
  flex-direction: column;
  position: relative;
  height:100%;

  .conversation-header  {

    z-index: 11;
    justify-content: space-between;
    //padding-top:1rem;
    padding: 1.75rem;
    display: flex;
    border-bottom: 1px solid #2c3034;

    .conversation-back {
      flex-grow: 1;
      cursor: pointer;
      //margin-left: 1rem;
      color:#fff;
      i {
        font-size: 1.5rem;
      }
    }

    .conversation-info {

      flex-grow: 5;

      .info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .info-image {
          flex-grow: 1;
          img {
            border-radius: 50%;
            max-width: 48px;
          }
        }
        .info-title {
          flex-grow:3;
        }

        .info-subtitle {
          display: flex;
          flex-direction: column;
          flex-grow: 3;
          .info-topic {
            flex-grow: 1;
          }
          .info-users {
            flex-grow: 1;
          }
        }



        .info-icon {

        }
      }


    }
    .conversation-nav {
      flex-grow: 1;
      display:flex;
      flex-direction: row;

      .navbar-nav {
        display: flex;
        flex-direction: row;
        gap:1rem;
      }

      .conversation-search-menu {
        flex-grow: 1;
        background-color: #2c3034!important;
        .conversation-search {

          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          .search-text {
            line-height: 1.5rem;
          }

        }
      }


      ul.navbar-nav i {
        font-size: 1.5rem;
      }

      a.dropdown-toggle {

        &:after {
          display: none;
        }
      }
    }
  }

  .conversation-footer {
    z-index: 10000;
    display: flex;
    position: absolute;
    width: 100%;
    bottom:5px;
    justify-content: space-between;
    border-top: 1px solid #2c3034;
    padding: 1rem;
    flex-direction: column;
  }
}