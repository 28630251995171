#mse-overlays {
  /* fixed position a zero-height full width container */
  position: fixed;
  top: 0; /* or whatever position is desired */
  left: 0;
  right: 0;
  height: 0;
  /* center all inline content */
  text-align: center;
  pointer-events: none;
  opacity: 0.7;
  filter: Alpha(Opacity=70);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 999999999;

  div {
    /* make the block inline */
    display: inline-block;
    /* reset container's center alignment */
    text-align: left;
    border-radius: 5px;
    border: 1px solid #000000;
    background-color: #fff;
    opacity: 1.0;
    filter: Alpha(Opacity=100);
    padding: 10px;
    margin-bottom: 25%;
    margin-top: 25%;
    z-index: 999999999;
    pointer-events: all;
  }

}