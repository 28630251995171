.qr {
  text-align: center;
}

.qr-info-container {
  display:flex;



  .qr-info {
    display:flex;

    .qr-wallet-name {
      display:block;
      text-align: center;
      color:#333333;
      font-size: 0.5em;
      font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
      font-weight: 400;
      padding-left:5px;
    }

    &.qr-wallet-code {
      text-align: center;
      justify-content: right;
      color:#000;
      font-size: 0.5em;
      font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
      font-weight: 600;
      padding:5px;
      border:1px solid #eee;
    }

  }
}

.qr-type-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .qr-type {
    text-align: left;
    color:#d42a2a;
    padding:1px;
    padding-left:5px;
    font-size: 0.4em;
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
    font-weight: 300;
  }

  .qr-domain {
    text-align: right;
    padding:1px;
    color:#d42a2a;
    font-size: 0.4em;
    font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
    font-weight: 300;
  }
}

.lvp-color-red-light {
  color: $lvp-ci-color-red-light;
}

.lvp-color-red-dark {
  color: $lvp-ci-color-red-dark;
}